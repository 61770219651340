class ArticleResults extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    // Add event listener to button
    const viewAllArticles = this.querySelector('#viewAllArticles');
    console.log('viewAllArticles', viewAllArticles);
    if (viewAllArticles) {
      viewAllArticles.addEventListener('click', () => {
        const hiddenArticles = this.querySelectorAll('.hidden-article');
        hiddenArticles.forEach((article) => {
          article.classList.remove('hidden-article');
        });
        viewAllArticles.style.display = 'none'; // Hide button after use
      });
    }
  }
}

customElements.define('article-results', ArticleResults);
